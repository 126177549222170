import React from 'react';
import './titleBar.css';

export default function TitleBar() {

    return (
        <div className='titlebar-container'>
            <div className='titlebar-title-container-1'>
                <div className='titlebar-words letter'>P</div>
                <div className='titlebar-words word-1'>lastic</div>
                <div className='titlebar-words word-2'>rinters</div>
            </div>
        </div>
    )
}