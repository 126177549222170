import React, { useEffect, useState, useRef } from 'react';
import { Accordion, Form, InputGroup } from 'react-bootstrap';
import './qrStyler.css';
import QRCodeStyling from 'qr-code-styling';

export default function QRStyler() {

    const qrRef = useRef(null);
    const [ data, setData ] = useState('https://www.plasticprinters.com');
    const [ fileExt, setFileExt ] = useState('png');
    const [ width, setWidth ] = useState(100);
    const [ height, setHeight ] = useState(100);

    const handleDataChange = (e) => {
        e.preventDefault();
        setData(e.target.value);
    }
    const handleSizeChange = (e) => {
        setHeight(e.target.value);
        setWidth(e.target.value);
    }

    const handleSelect = (e) => {
        setFileExt(e.target.value);
        if(e.target.value === 'svg') {
            qrCode.update({
                type: 'svg'
            })
        } else qrCode.update({
            type: 'canvas'
        })
    }

    const downloadQR = () => {
        qrCode.download({
            extension: fileExt
        });
    }

    const qrCode = new QRCodeStyling({
        margin:0,
        qrOptions: {
            typeNumber:"0",
            mode:"Byte",
            errorCorrectionLevel:"Q"
        },
        imageOptions: {
            hideBackgroundDots:true,
            imageSize:1,
            margin:0
        },
        dotsOptions: {
            type:"rounded",
            color:"#4d4d4f"
        },
        backgroundOptions: {
            color:"#ffffff"
        },
        image:null,
        dotsOptionsHelper: {
            colorType: {
                single:true,
                gradient:false
            },
            gradient: {
                linear:true,
                radial:false,
                color1:"#6a1a4c",
                color2:"#6a1a4c",
                rotation:"0"
            }
        },
        cornersSquareOptions: {
            type:"dot",
            color:"#0093c9"
        },
        cornersSquareOptionsHelper: {
            colorType: {
                single:true,
                gradient:false
            },
            gradient: {
                linear:true,
                radial:false,
                color1:"#000000",
                color2:"#000000",
                rotation:"0"
            }
        },
        cornersDotOptions: {
            type:"dot",
            color:"#0093c0"
        },
        cornersDotOptionsHelper: {
            colorType: {
                single:true,
                gradient:false
            },
            gradient: {
                linear:true,
                radial:false,
                color1:"#000000",
                color2:"#000000",
                rotation:"0"
            }
        },
        backgroundOptionsHelper: {
            colorType: {
                single:true,
                gradient:false
            },
            gradient: {
                linear:true,
                radial:false,
                color1:"#ffffff",
                color2:"#ffffff",
                rotation:"0"
            }
        }
    })

    useEffect(() => {
        qrCode.append(qrRef.current);
    },[data, width, height]);

    useEffect(() => {
        qrCode.update({
            data: data,
            width: width,
            height: height
        });
    },[data, width, height, fileExt])

    

    return (
        <>
        <div className='qr-container'>
            <Accordion className='qr-form-container' defaultActiveKey='0'>
                <Accordion.Item eventKey='0'>
                    <Accordion.Header>Main Options (without Logo)</Accordion.Header>
                    <Accordion.Body>
                        <InputGroup className='mb-2'>
                            <Form.Label>Data</Form.Label>
                            <input type='text' className='data-input' id='data-input' value={data} onChange={(e) => setData(e.target.value)} onBlur={(e) => handleDataChange(e)} />
                        </InputGroup>
                        <InputGroup className='mb-2'>
                            <Form.Label>Width</Form.Label>
                            <input type='text' className='width-input' id='width-input' value={width} onChange={(e) => setWidth(e.target.value)} onBlur={(e) => handleSizeChange(e)}/>
                        </InputGroup>
                        <InputGroup>
                            <Form.Label>Height</Form.Label>
                            <input type='text' className='height-input' id='height-input' value={height} onChange={(e) => setHeight(e.target.value)} onBlur={(e) => handleSizeChange(e)} />
                        </InputGroup>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <div className='qr-side-container'>
                <Form.Select defaultValue={fileExt} onChange={(e) => handleSelect(e)}>
                    <option value='png'>.png</option>
                    <option value='svg'>.svg</option>
                </Form.Select>
                <button className='download-btn' onClick={downloadQR}>Download</button>
            </div>
        </div>
        <div className='qr-preview-container'>
            <div ref={qrRef}>
            </div>
        </div>
        </>
    )
}