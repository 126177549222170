import React from 'react';
import './infoBar.css';


export default function InfoBar() {

    return (
        <div className='infobar-container'>
            <div className='infobar-info'>
                QR Generator
            </div>
            <div className='infobar-subinfo'>
                Enjoy personalizing your own QR codes!
            </div>
        </div>
    )
}