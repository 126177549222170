import React from 'react';
import TitleBar from './components/TitleBar';
import InfoBar from './components/InfoBar';
import QRStyler from './components/QRStyler';
import QRStyler2 from './components/QRStyler2';
function App() {
  return (
    <>
      <TitleBar />
      <InfoBar />
      <QRStyler />
      <QRStyler2 />
    </>
  );
}
export default App;